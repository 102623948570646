import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { useRequests } from './requests';
import { setSubscriptionStatus, clearSubscriptionStatus } from './redux/reducers/authReducer';
import { useCallback } from 'react';

export const useSubscription = () => {
  const dispatch = useDispatch();
  const { getSubscriptionStatus } = useRequests();
  
  const subscription = useSelector((state: RootState) => ({
    isSubscribed: state.auth.isSubscribed,
    activePlanId: state.auth.activePlanId,
  }));

  const handleFetchSubscriptionStatus = useCallback(async () => {
    try {
      const subscriptionResponse = await getSubscriptionStatus();
      const { is_subscribed, active_plan_id } = subscriptionResponse.data || {};
      dispatch(setSubscriptionStatus({ isSubscribed: is_subscribed, activePlanId: active_plan_id }));
      return { isSubscribed: is_subscribed, activePlanId: active_plan_id };
    } catch (error) {
      console.error('Error getting subscription status:', error);
      return { isSubscribed: false, activePlanId: null };
    }
  }, [dispatch, getSubscriptionStatus]);

  const clearSubscription = () => {
    dispatch(clearSubscriptionStatus());
  };

  return {
    ...subscription,
    handleFetchSubscriptionStatus,
    clearSubscription,
  };
};
