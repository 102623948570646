import { RouteObject } from "react-router";
import { lazy, Suspense } from "react";
import { Navigate } from 'react-router-dom';

import SuspenseLoader from "./components/common/Loaders/SuspenseLoader";
import BaseLayout from "./layouts/BaseLayout";
import SpifexLayout from "./layouts/SpifexLayout";
import { PermissionMiddleware } from 'src/middlewares/PermissionMiddleware';
import SuccessRouteMiddleware from "./middlewares/SuccessRouteMiddleware";
import OwnerRoute from "./middlewares/OwnerRoute";
import { SubscriptionMiddleware } from "./middlewares/SubscriptionMiddleware";

const Loader = (Component: React.ComponentType) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Auth
const SignUp = Loader(
    lazy(() => import('src/pages/Auth/SignUp'))
);

const SignIn = Loader(
    lazy(() => import('src/pages/Auth/SignIn'))
);

// Email Verification
const EmailVerification = Loader(
    lazy(() => import('src/pages/Auth/EmailVerification'))
);

const Enterprise = Loader(
    lazy(() => import('src/pages/EnterprisePanel'))
);

// CashFlow and Settled
const TestEntryPage = Loader(
    lazy(() => import('src/pages/TestEntryPage'))
);

const CashFlowSettledPage = Loader(
    lazy(() => import('src/pages/CashFlowSettledPage'))
);

// Settings
const Settings = Loader(
    lazy(() => import('src/pages/Settings'))
);

// AI Content Generator
const AIContentGenerator = Loader(
    lazy(() => import('src/pages/AIContentGenerator'))
);

// Reports
const Reports = Loader(
    lazy(() => import('src/pages/Reports'))
);

// Subscription Management
const SubscriptionManagement = Loader(
    lazy(() => import('src/pages/SubscriptionManagement'))
  );

// Status
const PurchaseConfirmation = Loader(
    lazy(() => import('src/pages/Status/PurchaseConfirmation'))
);
const Status404 = Loader(
    lazy(() => import('src/pages/Status/Status404'))
);
const Status500 = Loader(
    lazy(() => import('src/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
    lazy(() => import('src/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
    lazy(() => import('src/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
    {
        path: '*',
        element: <BaseLayout />,
        children: [
            {
            path: '*',
            element: <Status404 />
            }
        ]
    },
    {
        path: '/',
        element: <BaseLayout />,
        children: [
            // Auth
            {
                path: 'signup',
                element: <SignUp />
            },
            {
                path: 'signin',
                element: <SignIn />
            },
            {
                path: 'verify-email/:uidb64/:token/',
                element: <EmailVerification />
            },
            {
                path: '',
                element: <Navigate to="/cashflow" replace />
            }
        ]
    },
    {
        path: '/',
        element: <SpifexLayout />,
        children: [
            // Test
            {
                path: 'test',
                element: (
                        <TestEntryPage />
                )
            },
            // Cash Flow
            {
                path: 'cashflow',
                element: (
                    <PermissionMiddleware codeName="view_cash_flow_button" redirectTo={'/settled'}>
                        <CashFlowSettledPage />
                    </PermissionMiddleware>
                )
            },
            // Settled
            {
                path: 'settled',
                element: (
                    <PermissionMiddleware codeName="view_settled_button" redirectTo={'/reports'}>
                        <CashFlowSettledPage />
                    </PermissionMiddleware>
                )
            },
            // Reports
            {
                path: 'reports',
                element: (
                    <SubscriptionMiddleware redirectTo="/settings">
                        <PermissionMiddleware codeName="view_report_button" redirectTo={'/settings'}>
                            <Reports />
                        </PermissionMiddleware>
                    </SubscriptionMiddleware>
                )
            },
            // Settings
            {
                path: 'settings',
                element: <Settings />
            },
            // Enterprise
            {
                path: 'enterprise',
                element: <Enterprise />
            },
            // Subscription Management
            {
                path: 'subscription-management',
                element: (
                    <OwnerRoute>
                        <SubscriptionManagement />
                    </OwnerRoute>
                ),
            },
            // AI Content Generator
            {
                path: 'ai-content-generator',
                element: <AIContentGenerator />
            },
            {
                path: 'status',
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />
                    },
                    {
                        path: 'success',
                        element: (
                            <SuccessRouteMiddleware>
                                <PurchaseConfirmation />
                            </SuccessRouteMiddleware>
                        )
                    },
                    {
                        path: '500',
                        element: <Status500 />
                    },
                    {
                        path: 'maintenance',
                        element: <StatusMaintenance />
                    },
                    {
                        path: 'coming-soon',
                        element: <StatusComingSoon />
                    }
                ]
              }
        ]
    }
];

export default routes;
